import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import 'whatwg-fetch'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Layout from '../layouts/index'

class Team extends React.Component {
  constructor() {
    super()
    this.state = {
      submitted: false,
    }
  }

  header() {
    return (
      <div className="medium-12 medium-offset-0 small-10 small-offset-1 cell">
        <h3 className="text-center">Meet the Core Team</h3>
      </div>
    )
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const { photoIvan, photoStan } = this.props.data

    const imageStyle = {
      width: 'auto',
      height: 'auto',
      left: '50%',
      transform: 'translateX(-50%)',
      maxHeight: '200px',
    }

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={'The Team / ' + siteTitle} />

          <div className="pagehead">
            <div className="pagehead__wrap black small" />
          </div>

          <div className="callout white">
            <div className="grid-container">
              <div className="grid-x">{this.header()}</div>
              <div className="grid-x" style={{ padding: '2em 0' }}>
                {/*<div className="cell medium-3 small-12 text-center">*/}
                {/*    <Img sizes={photoIvan.sizes} style={imageStyle} imgStyle={imageStyle}/>*/}
                {/*    <p>*/}
                {/*        <b>Ivan Dotsenko</b><br/>*/}
                {/*        +421 944 028 184<br/>*/}
                {/*        <small>Slovakia</small>*/}
                {/*    </p>*/}
                {/*</div>*/}
                <div className="cell medium-3 small-12 medium-offset-1 text-center">
                  <GatsbyImage
                    image={photoStan?.gatsbyImageData}
                    style={imageStyle}
                    imgStyle={imageStyle}
                  />
                  {/*<Img fixed={photoStan.fixed} style={imageStyle} imgStyle={imageStyle}/>*/}
                  <p>
                    <b>Stanislav Smovdorenko</b>
                    <br />
                    +421 904 616 603
                    <br />
                    <small>Slovakia</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Team

export const pageQuery = graphql`
  query TeamQuery {
    site {
      siteMetadata {
        title
      }
    }

    photoStan: imageSharp(id: { regex: "/stan-photo/" }) {
      gatsbyImageData(layout: FIXED)
    }
  }
`
